<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="w-100 text-right">
          <button class="btn btn-sm btn-info rounded-0" @click="setList">
            <i class="fas fa-sync"></i>
          </button>
        </div>

        <div class="table-responsive shadow">
          <table class="table table-bordered">
            <tr class="bg-bb">
              <th>إسم المزاود</th>
              <th>قيمة المزاودة</th>
              <th>التاريخ</th>
              <th>الوقت</th>
              <th>حذف</th>
            </tr>
            <tr v-if="emptyList">
              <td colspan="5">لا يوجد</td>
            </tr>
            <tr
              v-else
              v-for="bid in list"
              :key="bid.id"
              :id="`bid-log-${bid.id}`"
            >
              <td>
                <router-link
                  v-if="bid.role === ClientRole"
                  :to="{ name: 'users.edit', params: { user: bid.user_id } }"
                  class="link"
                >
                  {{ bid.name }}
                </router-link>
                <label v-else>{{ bid.name }}</label>
              </td>
              <td>
                <span class="link">{{ bid.bid }}</span
                >&nbsp;<span v-if="bid.bid">دينار</span>
              </td>
              <td v-if="!!bid.date">{{ bid.date }}</td>
              <td v-if="!!bid.time">{{ bid.time }}</td>
              <td>
                <modal-btn
                  btn-class="pm-0 text-danger"
                  v-if="!bid.deleted_at"
                  :target="`#delete-bid${bid.id}`"
                  title="حذف مزاودة"
                >
                  <i class="fas fa-trash-alt"></i>
                </modal-btn>
                <span class="text-danger font-weight-bold" v-else>محذوفة</span>
              </td>
              <teleport to="#app" v-if="!bid.deleted_at">
                <items-destroyer
                  :remove-raw="false"
                  title="حذف مزاودة"
                  :row-id="`bid-log-${bid.id}`"
                  :target-id="`delete-bid${bid.id}`"
                  :url="`${defaultUrl}/${bid.id}`"
                  @deleted="bidDeleted"
                >
                  <p class="text-center text-danger">
                    هل أنت متأكد من حذف المزاودة ؟
                  </p>
                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th v-if="!!bid.name">إسم المزاود</th>
                        <th v-if="!!bid.bid">المزاودة</th>
                      </tr>
                      <tr>
                        <td v-if="!!bid.name">
                          <span>{{ bid.name }}</span>
                        </td>
                        <td v-if="!!bid.bid">
                          <span class="link">{{ bid.bid }}</span
                          >&nbsp;<span>دينار</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <p>
                    <small class="link danger"
                      >يرجى الإنتباه: في حال حذف اعلى مزاودة سوف تتغير القيمة
                      الحالية للمزاد</small
                    >
                  </p>
                </items-destroyer>
              </teleport>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import ModalBtn from "@/components/UI/ModalBtn";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import UserRole from "@/extra/enums/UserRole";
import ThePaginator from "@/components/UI/ThePaginator";

export default {
  components: { ThePaginator, ItemsDestroyer, ModalBtn },
  mixins: [pagination],
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    shouldReload() {
      return this.$store.getters.shouldReload;
    },
    auctionId() {
      return this.$route.params.auction;
    },
    defaultUrl() {
      return "auction-bids";
    },
    ClientRole() {
      return UserRole.Client;
    },
  },
  methods: {
    async bidDeleted() {
      await this.setList();
    },
  },
  async created() {
    this.formData = { auction: this.auctionId };
  },
};
</script>
